@media screen and (max-width: 800px) {
  /* LANDING */
  .title {
    font-size: 50px;
  }
  .animated-text  {
    font-size: 19px;
  }
  .white-line {
    width: 8rem;
  }
  .marquee-text {
    font-size: 18px;
  }
  .react-marquee-line-hor {
    padding-right: 25px;
  }
  /* OFFERS */
  .offers {
    padding: 6rem 5rem;
  }
  .offers-title {
    font-size: 40px;
  }

  /* ABOUT */
  .aboutus-slogan {
    width: max-content;
  }
  .card-1 {
    width: 35rem;
  }
  .card-2 {
    width: 135vw;
  }
  .aboutus-sm-image {
    display: none;
  }
  .card-3 {
    width: 80vw;
  }
  .card-4,
  .card-5,
  .card-6 {
    width: 80vw;
  }
  .carousel-card {
    gap: 8rem;
  }
  .form-second {
    width: 100%;
  }
  .form-textarea {
    width: 100%;
  }

  /* STATS */
  .stats {
    padding: 6rem 5rem;
  }

  /* FOOTER */
  .footer {
    flex-direction: column;
    height: max-content;
  }
  .footer-first {
    margin-bottom: 3rem;
    align-items: center;
  }
  .legal {
    margin: 1rem auto;
  }
  .legal-link {
    margin-right: 0.5rem;
    margin-left: 0.5rem;
  }
  .footer-copyright {
    margin-top: 0.5rem;
  }
  .footer-right {
    text-align: center;
    align-self: center;
    align-items: center;
  }
  .footer-long-text {
    text-align: center;
  }
  .footer-socials {
    align-items: center;
  }
  .footer-socials-container {
    margin-top: 1rem;
  }
  .footer-socials-icon {
    margin-right: 0.25rem;
    margin-left: 0.25rem;
  }
  .stats-box {
    grid-template-columns: repeat(2, 1fr);
  }
  .blob1 {
    width: 7rem;
  }

  .blob2 {
    width: 20rem;
  }
  .blob3 {
    width: 10rem;
  }

  .footer-copyright {
    text-align: center;
  }

  .facebook {
    text-align: center;
    width: 100%;
  }
}
