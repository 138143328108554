.aboutus {
  background: #151515;
  position: relative;
}

.aboutus-container {
  position: absolute;
}

.aboutus-divs {
  width: 100vw;
  display: flex;
}

.aboutus-title {
  position: absolute;
  top: 12rem;
  left: 50%;
  font-size: 64px;
  font-weight: 900;
  color: #efefef;
  transform: translate(-50%, 0);
}

.aboutus-slogan {
  position: absolute;
  color: #f33c91;
  top: 17rem;
  left: 50%;
  font-weight: 600;
  font-size: 22px;
  transform: translate(-50%, 0);
  z-index: 5;
  text-align: center;
}

.carousel-cont {
  position: relative;
  min-height: 400vh;
  height: max-content;
}

.carousel-inner {
  display: flex;
  overflow: hidden;
  position: sticky;
  top: 0;
  align-items: center;
  height: 100vh;
}

.carousel-card {
  display: flex;
  gap: 15rem;
}

.card-container {
  overflow: hidden;
  position: relative;
  min-height: 450px;
  min-width: 50vw;
  margin-top: 25rem;
}

.card-inner {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
  transition-duration: 300ms;
}

.card-inner-2 {
  display: grid;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  place-content: flex-start;
}

.card-title {
  font-size: 36px;
  font-weight: 700;
  color: #efefef;
  text-transform: uppercase;
  margin: 0;
  margin-bottom: 1.5rem;
}

.card-description {
  font-size: 20px;
  color: #efefefc9;
  margin: 0;
}

.ecg {
  font-weight: 700;
  color: #efefef;
}

.media {
  font-weight: 400;
  color: #efefef;
}

.card-img {
  display: none;
}

.card-inner-title {
  margin: 0;
}

.card-flex-inner {
  width: 30rem;
  margin-bottom: 3rem;
  margin-right: 3rem;
}

.card-inner-title {
  color: #efefef;
  font-weight: 500;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
  font-size: 20px;
}

.card-2 {
  width: 85vw;
}

.last-card-desc {
  align-self: center;
}

.gap {
  width: 2rem;
  display: inline-block;
}

/* SOCIAL MEDIA */
.sm-inner-management {
  margin-bottom: 2rem;
}

.sm-inner-advertising {
  margin-bottom: 4rem;
}

.aboutus-sm-inner {
  width: 55rem;
}

.sm-followus {
  color: #efefef;
  font-size: 24px;
  font-weight: 700;
  margin: 0;
  margin-right: 1rem;
}

.card-3 {
  width: 85vw;
}

/* FORM */
.form-label {
  margin: 0;
  margin-bottom: 0.5rem;
  margin-top: 1rem;
  color: #efefef;
}

.form-required {
  color: rgb(253, 86, 86);
  font-weight: 700;
  font-size: 18px;
}

.influencer-text {
  font-family: 18px;
  text-transform: uppercase;
  font-weight: 600;
  margin-left: 0.5rem;
}

.checkbox {
  width: 1.2rem;
  height: 1.2rem;
  align-self: center;
  cursor: pointer;
}

.form-input {
  width: 18rem;
  height: 2.5rem;
  font-family: "Outfit", sans-serif;
  padding: 0 0.5rem;
  box-sizing: border-box;
}

.form-textarea {
  box-sizing: border-box;
  padding: 0.5rem 0.5rem;
  resize: none;
  width: 25rem;
  height: 100%;
}

.form-second {
  margin-left: 2rem;
}

.form-submit {
  font-family: "Outfit", sans-serif;
  width: 10rem;
  margin-top: 1rem;
  height: 2.5rem;
  color: #efefef;
  background-color: #f33c91;
  border: none;
  border-radius: 0.2rem;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
}

/* SOCIAL ICONS */
.about-socials-icon {
  width: 1.9rem;
  margin-left: 0.5rem;
  opacity: 0.8;

  transition: 0.2s ease-in-out;
}

.about-socials-icon:hover {
  opacity: 1;

  transition: 0.2s ease-in-out;
}
