.aboutus-sm-image {
    position: absolute;
    top: 0;
    right: 0;
    width: 35rem;
    height: 58rem;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.react-marquee-line-hor-viewBox {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    white-space: nowrap;

    transform: translate(0, -25%);
}

.marquee-ig {
    height: 26.5rem;
    width: auto;
}