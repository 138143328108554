@media screen and (max-width: 500px) {
  /* LANDING */
  .white-line {
    margin: auto 1rem;
    width: 2rem;
  }
  .title {
    font-size: 42px;
  }

  /* OFFERS */
  .offers {
    padding: 6rem 2rem;
  }

  /* ABOUT */
  .aboutus-title {
    font-size: 40px;
    width: max-content;
  }
  .gap {
    width: 1rem;
  }
  .animated-text  {
    font-size: 18px;
  }
  .card-1 > .card-inner-2 {
    width: 90%;
  }
  .card-2 {
    width: 50rem;
  }
  .card-3 > .card-inner-2 {
    width: 90%;
  }
  .card-4 > .card-inner-2 {
    width: 90%;
  }
  .card-5 > .card-inner-2 {
    width: 90%;
  }
  .carousel-card {
    gap: 4rem;
  }
  .card-title {
    font-size: 22px;
  }
  .card-description {
    font-size: 18px;
    color: #efefefc9;
    margin: 0;
  }
  .sm-inner-advertising {
    margin-bottom: 2rem;
  }

  .card-title {
    font-size: 22px;
    margin-bottom: 0.5rem;
  }
  .form-label {
    margin-top: 0.5rem;
  }
  /* STATS */
  .stats {
    padding: 6rem 2rem;
  }

  /* FOOTER */
  .footer {
    padding: 5rem 2rem;
  }
  .footer-copyright {
    text-align: center;
    margin-bottom: 1.5rem;
  }
  .blob1 {
    width: 7rem;
    right: 5rem;
  }

  .blob2 {
    width: 20rem;
  }
  .blob3 {
    width: 10rem;
    top: 40%;
  }

  .aboutus-slogan {
    width: 90%;
    text-align: center;
    top: 15.5rem;
  }
}
