.footer {
  background: #151515;
  height: 20rem;
  padding: 5rem;
  box-sizing: border-box;
  color: rgba(240, 240, 240, 0.8);
  font-weight: 400;
  font-size: 20px;

  justify-content: space-between;
}

.footer-first {
  justify-content: space-between;
}

.footer-copyright {
  margin: 0;
  font-size: 18px;
}

.footer-right {
  width: 40rem;
  justify-content: space-between;
}

.footer-long-text {
  margin: 0;
}

.footer-connect {
  color: #efefef;
  text-decoration: underline;
  font-weight: 600;
  cursor: pointer;
}

.legal {
  margin-top: 1rem;
  align-self: flex-start;
}

.legal-link {
  font-size: 18px;
  margin-right: 1rem;
  color: rgba(240, 240, 240, 0.8);
}

.footer-socials-container {
  margin-bottom: 0.5rem;
}

.footer-socials-icon {
  width: 2rem;
  margin-right: 0.5rem;
  opacity: 0.8;

  transition: 0.2s ease-in-out;
}

.footer-socials-icon:hover {
  opacity: 1;

  transition: 0.2s ease-in-out;
}

.facebook {
  width: 60%;
  margin-top: 1rem;
  color: #efefef6b;
  font-size: 18px;
}