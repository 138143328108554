@media screen and (max-width: 1500px) {
  /* LANDING */
  .white-line {
    width: 10rem;
  }
  .title {
    font-size: 60px;
  }
  .animated-text  {
    font-size: 21px;
  }
  .marquee-text {
    font-size: 22px;
  }

  /* OFFERS */
  .offers {
    padding: 6rem 15rem;
  }
  .offers-title {
    font-size: 55px;
  }
  .offers-description {
    width: unset;
  }
  .offers-inner {
    grid-template-columns: repeat(2, 1fr);
  }

  /* ABOUT */
  .aboutus-sm-inner {
    width: 40rem;
  }
  .aboutus-sm-image {
    width: 30rem;
  }
  .card-title {
    font-size: 32px;
  }

  /* STATS */
  .stats {
    padding: 6rem 10rem;
  }
  .stats-title {
    font-size: 55px;
  }
  .stats-description {
    width: unset;
  }

  /* FOOTER */
  .footer-long-text {
    text-align: end;
  }
  .footer-socials {
    align-items: end;
  }
  .footer-socials-icon {
    margin-right: 0;
    margin-left: 0.5rem;
  }
}
