.marque {
  overflow: hidden;
  height: 50px;
  width: 100vw;
  position: absolute;
  bottom: 0;

  color: rgba(255, 255, 255, 0.295);
}

.marquee-text {
  font-size: 25px;
  margin-right: -2rem !important;
  margin: auto auto !important;
  padding: 0 !important;

  font-family: "Outfit", sans-serif;
  font-weight: 500;
}
