@media screen and (max-width: 600px) {
  /* LANDING */

  /* OFFERS */

  /* ABOUT */
  .form-inner {
    flex-direction: column;
  }
  .form-second {
    margin-left: 0;
    width: 100%;
  }
  .card-6 {
    width: 110vw;
  }
  .card-2 {
    width: 175vw;
  }
  .card-2 > .card-inner-2 {
    width: 100%;
  }
  .card-inner-2 {
    width: 70%;
  }
  .aboutus-sm-inner {
    width: unset;
}
.card-5, .card-4, .card-3, .card-1 {
  width: 100vw;
}

  /* STATS */

  /* FOOTER */
  .footer-right {
    width: 100%;
  }
  .footer-long-text {
    margin-bottom: 1.5rem;
  }
}
