.landing {
  position: relative;
  height: 100vh;

  background: rgb(58, 58, 58);
  background: linear-gradient(
    180deg,
    rgba(58, 58, 58, 1) 0%,
    #0d0d0d 100%
  );

  overflow: hidden;
  z-index: 2;
}

.white-line {
  width: 15rem;
  height: 1px;
  background-color: #efefef;
  margin: auto 2rem;
  border-radius: 1rem;
}

.title {
  color: #efefef;
  font-size: 78px;
}

.slogan {
  color: #efefef;
  font-size: 22px;
}

.inner-landing {
  position: absolute;
  text-align: center;
  width: max-content;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.w600 {
  font-weight: 600;
}

.w400 {
  font-weight: 300;
}

.blob {
  position: absolute;
  opacity: 0.1;
}

.blob1 {
  width: 15rem;
  top: 10rem;
  left: 25rem;
}

.blob2 {
  right: -5rem;
  bottom: -5rem;
  width: 30rem;
}
.blob3 {
  left: -1rem;
  bottom: 5rem;
  width: 20rem;
}
