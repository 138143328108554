@media screen and (max-height: 800px) {

    .aboutus-title {
        top: 5rem;
    }

    .aboutus-slogan {
        top: 8rem;
    }

    .card-container {
        height: auto;
        min-height: 450px;
        margin-top: 15rem;
        transform: scale(0.8) !important;
    }
}