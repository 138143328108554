.contact-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s ease, visibility 0.5s ease;
  z-index: 10;
}

.contact-container.visible {
  opacity: 1;
  visibility: visible;
}

.contact-content {
  justify-content: space-evenly;

  position: relative;
  background: hsla(0, 0%, 94%, 0.9);

  width: 25rem;
  height: 35rem;

  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  transition: transform 0.5s ease;
}

.contact-container:not(.visible) .contact-content {
  transform: translateY(-100vh);
}

.contact-button {
  background: #ffffff;
  border: none;
  border-radius: 10rem;

  font-size: 32px;
  font-weight: 500;

  width: 3rem;
  height: 3rem;

  margin-top: 1rem;
  align-self: center;

  z-index: 15;

  cursor: pointer;
}

.contact-content > p {
  font-size: 24px;
  font-weight: 500;
  color: #3a3a3a;

  text-transform: uppercase;
  text-align: center;
}

.c-form-label {
  color: #3a3a3a;
  margin: 0;
  margin-bottom: 0.2rem;
  margin-top: 1rem;
  font-weight: 500;
}

.c-textarea {
  height: 5rem;
}

.c-first {
    margin-top: 0;
}


@media screen and (max-width: 500px) {

    .contact-content {
        width: 75%;
        height: 90vh;
      }

}