.animated-text {
  display: flex;
  font-weight: 500;
  color: #efefef;
  font-size: 22px;
  justify-content: center;
}

.animated-letter {
  display: inline-block;
  animation: changeColor 2s ease-in-out infinite;
  animation-delay: var(--animation-delay, 0s); /* Fallback for older browsers */
}

@keyframes moveUp {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(0px);
  }
}

@keyframes changeColor {
  0%,
  100% {
    color: hsla(0, 0%, 94%, 0.9);
  }
  50% {
    color: hsla(0, 0%, 94%, 0.1);
  }
}
