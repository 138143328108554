body {
  font-family: "Outfit", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;

  overflow-y: auto;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

.flex {
  display: flex;
}

.flexRow {
  flex-direction: row;
}

.flexColumn {
  flex-direction: column;
}

.pink {
  color: #f33c91;
}

.bold {
  font-weight: 600;
}