.stats {
  padding: 6rem 20rem;
  min-height: 75vh;
  background-color: #efefef;
  color: #3a3a3a;
  justify-content: center;
  position: relative;
}

.stats-title-container {
  align-items: center;
  text-align: center;
  margin-top: 2rem;
}

.stats-title {
  font-size: 68px;
  margin: 0;
}

.stats-description {
  font-size: 20px;
  width: 60rem;
  padding: 0;
  margin: 1rem 0 0 0;
}

.stats-box {
  margin-bottom: 6rem;
  text-align: center;

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;

  justify-content: space-between;
}

.stats-text {
  color: #3a3a3a;
  font-size: 24px;
  font-weight: 500;
}

.stats-number {
  font-size: 55px;
  font-weight: 800;
  color: #f33c91;
  animation-name: colorChange;
  animation-duration: 5s;
}

@keyframes colorChange {
  from {
    color: transparent;
  }
  to {
    color: #f33c91;
  }
}

.stats-wave {
  position: absolute;
  width: 100%;
  top: -1rem;
  left: 0;
  height: 4rem;
}

.footer-wave {
  position: absolute;
  width: 100%;
  bottom: -1rem;
  left: 0;
  height: 4rem;
}
