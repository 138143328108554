.offers {
  padding: 6rem 20rem;
  min-height: 50vh;
  background-color: #efefef;
  color: #3a3a3a;
  justify-content: center;
  position: relative;
}

.offers-title-container {
  align-items: center;
  text-align: center;
  margin-top: 2rem;
}

.offers-title {
  font-size: 68px;
  margin: 0;
}

.offers-description {
  font-size: 20px;
  width: 60rem;
  padding: 0;
  margin: 1rem 0 0 0;
}

.black-line {
  width: 100%;
  height: 1px;
  background-color: #3a3a3a;
  border-radius: 1rem;
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.offers-inner {
  margin-bottom: 6rem;

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  width: 100%;
  justify-content: space-between;
}

.offer-child {
  margin-bottom: 2.5rem;
  /* width: 22rem; */
  box-sizing: border-box;
}

.child-title {
  font-size: 28px;
  font-weight: 600;
  color: #f33c91;
  margin: 0;
  margin-bottom: 1.5rem;
}

.child-description {
  font-size: 20px;
  margin: 0;
}

.offers-wave {
  position: absolute;
  bottom: -1rem;
  left: 0;
  width: 100%;
  height: 3rem;
}

.offers-wave-upper {
  position: absolute;
  top: -1rem;
  left: 0;
  width: 100%;
  height: 3rem;
  z-index: 1;
}

.offers-images {
  justify-content: center;
  margin-bottom: 2rem;
  flex-wrap: wrap;
}

.offers-inner-img {
  width: auto;
  height: 3rem;
  margin: 1rem;
  user-select: none;
  pointer-events: none;
}

.br2 {
  display: none;
}