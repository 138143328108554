@media screen and (max-width: 375px) {
  /* LANDING */
  .home-link > img {
    width: 5rem;
  }
  .contact {
    font-size: 14px;
  }
  .white-line {
    display: none;
  }
  .title {
    font-size: 36px;
  }
  .animated-text {
    font-size: 18px;
  }

  /* OFFERS */
  .offers-title {
    font-size: 32px;
  }
  .offers-description {
    font-size: 18px;
    margin: 0.5rem 0 0 0;
  }
  .child-title {
    margin-bottom: 0.5rem;
  }

  /* ABOUT */
  .aboutus-title {
    font-size: 36px;
    width: max-content;
  }

  .aboutus-slogan {
    font-size: 18px;
    width: 90%;
    text-align: center;
  }
  .card-flex-inner {
    margin-bottom: 1rem;
    margin-right: 1rem;
  }
  .sm-inner-management {
    margin-bottom: 0.5rem;
  }
  .sm-inner-advertising {
    margin-bottom: 1rem;
  }
  .card-description {
    font-size: 17px;
  }
  .form-input {
    width: 100%;
  }
  .influencer-text {
    font-family: 16px;
  }
  .card-5 {
    width: 150vw;
  }
  .card-5 > .card-inner-2 > .aboutus-sm-inner {
    flex-direction: row;
  }
  .sm-inner-management {
    margin-right: 2rem;
  }
  .card-6 {
    width: 210vw;
  }
  .card-6 > .card-inner-2 {
    width: 100%;
  }
  .form-inner {
    flex-direction: row;
    width: 75%;
  }
  .form-first {
    width: 30rem;
    margin-right: 2rem;
  }
  /* STATS */

  /* FOOTER */
  .footer {
    padding: 5rem 2rem;
  }
  .footer-logo > img {
    width: 10rem;
  }
  .footer-copyright {
    font-size: 16px;
  }
  .footer-long-text {
    font-size: 16px;
  }

  .blob1 {
    width: 7rem;
    left: 70%;
  }
  
  .blob2 {
    width: 20rem;
  }
  .blob3 {
    width: 10rem;
    top: 40%;
  }
  
}



