.outer-privacy-box {
    height: 100%;
    width: 100%;
    background-color: #1f1f1f;
}

.privacy-box {
    margin: auto;
    width: 80%;
    padding: 10rem 0;
    color: #efefef;
}

.b-title {
    font-weight: 700;
}

.b-subtitle {
    font-weight: 600;
}

.b-date {
    font-weight: 400;
    margin-left: .5rem;
}

.b-desc {
    margin-top: 4rem;
    color: #c7c7c7;
}

.b-highlight {
    font-weight: 500;
    color: #efefef;
}

.b-list {
    padding: .5rem 0 0 2rem;
    box-sizing: border-box;
}

.b-list-inner {
    padding: 0 0 0 1rem;
}

.b-dot {
    color: #efefef;
    font-size: 28px;
    padding: 0 .5rem;
    vertical-align: middle;
    line-height: 1;
}

.b-dot-text {
    line-height: 1.5;
}