.navbar {
  height: 5rem;
  width: -webkit-fill-available;
  background-color: #151515;
  justify-content: space-between;
  padding: 0 5rem;
  text-align: center;
  position: fixed;

  z-index: 4;
}

.home-link {
    margin: auto 0;
}

.contact {
    margin: auto 0;
    color: #efefef;
    text-decoration: underline;
}