@media screen and (max-width: 1200px) {
  /* LANDING */

  /* OFFERS */
  .card-inner-title {
    font-size: 18px;
  }
  .card-flex-inner {
    margin-bottom: 2rem;
    margin-right: 2rem;
  }
  .card-3 {
    width: 110vw;
  }
  .aboutus-sm-inner {
    width: 47rem;
  }

  /* ABOUT */

  /* STATS */
  .stats-text {
    font-size: 20px;
  }

  /* FOOTER */
  .footer {
    height: 22rem;
  }
  .footer-right {
    width: 35rem;
  }

  .blob1 {
    width: 10rem;
  }

  .blob2 {
    width: 25rem;
  }
  .blob3 {
    width: 15rem;
  }

  .br2 {
    display: block;
  }
}
