@media screen and (max-width: 700px) {
  /* LANDING */
  .navbar {
    padding: 0 2rem;
  }
  .home-link > img {
    width: 7.5rem;
  }
  .white-line {
    width: 5rem;
  }

  /* OFFERS */
  .offers-inner {
    grid-template-columns: repeat(1, 1fr);
    gap: 1rem;
  }
  .black-line {
    margin-bottom: 3rem;
  }
  .offer-child {
    text-align: center;
  }

  /* ABOUT */
  .aboutus-title {
    font-size: 50px;
    width: 90%;
    text-align: center;
  }
  .animated-text {
    font-size: 18px;
  }
  .card-title {
    font-size: 26px;
  }

  /* STATS */
  .stats-box {
    grid-template-columns: repeat(1, 1fr);
  }

  /* FOOTER */
}
